import { config, library, dom } from "@fortawesome/fontawesome-svg-core";

import "../controllers/index";

// Expose Autonumeric as a global for legacy reasons
import AutoNumeric from "autonumeric";
window.AutoNumeric = AutoNumeric;

config.mutateApproach = "sync";

// TODO: Switch to a custom kit where we don't have to list every icon in use
import {
  faAngleDown,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBell,
  faBuilding,
  faCircleCheck,
  faCircleUser,
  faClipboardList,
  faClose,
  faEllipsisV,
  faExclamationCircle,
  faFileInvoiceDollar,
  faGear,
  faGripLines,
  faMagnifyingGlass,
  faPaperclip,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import {
  faCalendar,
  faClock as faRegularClock,
  faFolder,
  faMessage,
  faTrashCan as faRegularTrashCan,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faAngleDown,
  faArrowLeft,
  faArrowRight,
  faBars,
  faBell,
  faBuilding,
  faCalendar,
  faCircleCheck,
  faCircleUser,
  faClipboardList,
  faClose,
  faEllipsisV,
  faExclamationCircle,
  faFileInvoiceDollar,
  faFolder,
  faGear,
  faGripLines,
  faMagnifyingGlass,
  faMessage,
  faPaperclip,
  faPlus,
  faRegularClock,
  faRegularTrashCan,
  faTrash,
  faEye,
  faEyeSlash,
);

dom.watch();
