import { Controller } from "@hotwired/stimulus"
import AutoNumeric from "autonumeric"

export default class extends Controller {
  // Optionally allow usage on a single input (element) or many input targets configured the same
  static targets = ["input"]
  static values = {
    separator: { type: String, default: ST.currency.separator }, // Decimals character
    delimiter: { type: String, default: ST.currency.delimiter }, // Thousands character
    decimalPlaces: { type: Number, default: ST.currency.precision }
  }

  connect() {
    if(!this.hasInputTarget) {
      this.configureAutoNumeric(this.element)
    }
  }

  inputTargetConnected(element) {
    this.configureAutoNumeric(element)
  }

  configureAutoNumeric(element) {
    element.classList.add("autonumeric-input")
    new AutoNumeric(element, this.options())
  }

  options() {
    return {
      unformatOnSubmit: true, // Ensure that we are submitting simple unformatted numbers
      decimalCharacter: this.separatorValue,
      digitGroupSeparator: this.delimiterValue,
      decimalPlaces: this.decimalPlacesValue,
      decimalPlacesRawValue: this.decimalPlacesValue,
    }
  }
}
