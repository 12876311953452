import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "resource",
    "resourcePosition",
    "dialogResourceName",
    "removeResourceButton"
  ]

  connect() {
    // Remove an empty field that is rendered by the nested form controller by default.
    this.resourceTargets.find(t => "newRecord" in t.dataset).remove();
  }

  openDialog(event) {
    const id = event.currentTarget.attributes["data-id"]?.value;
    const name = event.currentTarget.attributes["data-name"]?.value;

    if (id === undefined) {
      event.stopImmediatePropagation();
      event.currentTarget.parentElement.remove();
    } else {
      this.removeResourceButtonTarget.setAttribute("action", `/settings/job_task_resources/${id}`);
      this.dialogResourceNameTarget.innerHTML = name;
    }
  }

  sort(event) {
    this.resourcePositionTargets.forEach((position, index) => {
      position.value = index + 1;
    });
  }
}
