import { Application } from "stimulus";
import { registerControllers } from "stimulus-vite-helpers";

// Import 3rd party controllers
import { Alert, Dropdown, Modal, Slideover } from "tailwindcss-stimulus-components";
import ContentLoader from '@stimulus-components/content-loader';
import RailsNestedForm from '@stimulus-components/rails-nested-form'
import Sortable from '@stimulus-components/sortable';

const application = Application.start();
application.debug = process.env.NODE_ENV === "development";

// Controller files must be named *_controller.js.
const controllers = import.meta.glob("./**/*_controller.js", { eager: true });

// Register 3rd party Controllers
application.register("alert", Alert)
application.register("dropdown", Dropdown)
application.register('modal', Modal)
application.register('nested-form', RailsNestedForm)
application.register("slideover", Slideover)
application.register('sortable', Sortable)
application.register("content-loader", ContentLoader) // This is more of a temporary fix until we get hotwire and realtime notifications

// Register our controllers
registerControllers(application, controllers);
